import React from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
    contain: {
        height: "fit-content",
        width: "100%",
        minHeight: "400px",
        paddingTop: "30px",
        paddingBottom: "30px",
        disableGutters: true,
        [theme.breakpoints.up('md')]: {
            marginTop: "200px"
        },
        [theme.breakpoints.down('sm')]: { 
            marginTop: "100px"
        },
    }
}));

export default function Blog001() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const classes = useStyles();

  return (
      <Container maxWidth="md" style={{ textAlign: "center"}} className={classes.contain}>
        <Typography variant="h2" style={{fontSize: "25pt"}}>Blog #1 - Welcome!</Typography>
        <Typography variant="body2" style={{color: "grey"}}>
              28th December 2020 10:47
          </Typography>
          <Typography variant="body1">
          <p>
          Hello, and welcome to Tailered Motors first blog. We are a bunch of car lovers and enthusiests who want to share information to everyone who needs a motor in their lives!
          A lot of blogs online, as they've gained popularity switch their attention to very high-end motors which are out of reach to normal people. We want to change that.
        </p>
        <p>
          This is only a quick hello, but our first topic will be the world of financing. 
          Is it worth it? Why do it? Should you just spend cash on a car? 
          We'll offer a couple of opinions so you don't have to debate with yourself.
        </p>
        <p>
            See you on our first blog!
        </p>
          </Typography>
        
      </Container>
  );
}
