import React from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import ReactGA from 'react-ga';
import PCPImage from "../../images/Deposit .png";
import HPImage from "../../images/Folder 43 copy.png"

const useStyles = makeStyles((theme) => ({
    contain: {
        height: "fit-content",
        width: "100%",
        minHeight: "400px",
        paddingTop: "30px",
        paddingBottom: "30px",
        disableGutters: true,
        [theme.breakpoints.up('md')]: {
            marginTop: "200px"
        },
        [theme.breakpoints.down('sm')]: { 
            marginTop: "100px"
        },
    },
    wrapper: {
        width: "inherit",
        height: "200px",
        objectFit: "contain",
        overflow: "hidden",
      }
}));

export default function Blog002() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const classes = useStyles();

  return (
      <Container maxWidth="md" style={{ textAlign: "center"}} className={classes.contain}>
        <Typography variant="h2" style={{fontSize: "25pt"}}>Blog #2 - Finance</Typography>
        <Typography variant="body2" style={{color: "grey", textAlign: "left"}}>
              31st December 2020 21:32
          </Typography>
          <Typography variant="body1" style={{ textAlign: "left" }}>
            <p>
            Let's discuss the world of finance... is it a good idea? Why do it? What happens if I want to change cars early?
            With finance, there are many questions and many things that dealers won't tell you!
            
            <br />
            Finance is growing hugely in popularity due there being no need to purchasing a car fully, 
            i.e. meaning you can hold on to your own cash for longer and not have it in a depeciating asset.

            </p>
            <Typography variant="h2" style={{fontSize: "20pt"}}>General Finance</Typography>
            <p>
                Dealer Offerings:
                <ol>
                    <li>PCP - Personal Contract Purchase</li>
                    <li>HP - Hire Purchase</li>
                </ol>
                
                Other Purchase Routes:
                <ol>
                    <li>Asset</li>
                    <li>Lease</li>
                </ol>

                We'll cover leasing and asset finance in another blog post (as there is a lot to go through).
            </p>
            <Typography variant="h2" style={{fontSize: "20pt"}}>PCP</Typography>
            <p>
                What is PCP and how does it work?
                <ul>
                    <li>Buyer can put an initial deposit on a vehicle (usually 10% minimum)</li>
                    <li>Buyer pays monthly for the vehicle - you are the registered keeper (but not the owner)</li>
                    <li>Balloon value calculated for estimated value at the end of the contract term</li>
                    <li>Buyer settles vehicle in one of 3 ways; pays off balloon, trades in or sells (subject to contract terms)</li>
                </ul>

                <img src={PCPImage} alt="PCP Example" style={{width: "100%", maxWidth: "350px"}} />

            </p>

            <Typography variant="h2" style={{fontSize: "20pt"}}>PCP Final Balloon / GFV (Guaranteed Future Value)</Typography>
            <p>
                This is the most important part of any PCP (or finance) deal where you don't hold the full value of the vehicle.
                Most dealers have a system which automatically estimates vehicle values in the future which takes in multiple data sources.
                Traditional dealers use "Black Book" or things like Parkers Guide to get a good idea what can be used.

                <p /><b>TOP TIP</b> - Question the final value, and what it is based off, and what percentage of the metric it is set from. 
                In otherwords, if they are offering you 100% of the final trade in value, then you're more than likely not going to have any equity at the end of your contract term.
                This gives the dealer the opportunity to sell a car multiple times - i.e. because each buyer hands the keys back at the end of the term.
                <p /><b>TOP TIP</b> - Do your own research (if you can) to see what they are valued for on the open market (such as AutoTrader).

                <p />The GFV is an added advantage of a PCP contract because if the actual value of the car goes below the GFV then you have the option to hand the keys back at the end of the term.
            </p>
            
            <Typography variant="h2" style={{fontSize: "20pt"}}>PCP Deposits</Typography>
            <p>
                This can vary based on the motor and the finance provider, however it is normally expected for the purchaser to put down a minimum of 10% deposit.
                Buyers can put down more than 10%, and it would reduce the finance amounts; however it may not be beneficial at the end of the contract. 
                <p /><b>TOP TIP</b> - If you want to put more money down to reduce the value, ask for multiple quotes and see if you'd be better off.
                <br />Calculation: difference in monthly costs x contract length. Does this equal the extra what you'd put in?
            </p>
            <Typography variant="h2" style={{fontSize: "20pt"}}>PCP End of Contract</Typography>
            <p>
                Once the contract term has elapsed, there are multiple choices.

                <ul>
                    <li>Pay off the balance (balloon) and keep the car</li>
                    <li>Trade in the vehicle with a dealer - This would basically the dealer would settle your existing contract</li>
                    <li>Hand the keys back (i.e. the cars worth less than the GFV)</li>
                    <li>Selling privately (the route dealers don't want you to take) - this is covered in the "PCP, HP and Asset Finance Early Exits from Contract Terms" section. However for this to work, you need to sell before the end of the contract term.</li>
                </ul>
            </p>
            <Typography variant="h2" style={{fontSize: "20pt"}}>HP</Typography>
            <p>
                What is HP and how does it work?
                <ul>
                    <li>Buyer can put an initial deposit on a vehicle (usually 10% minimum)</li>
                    <li>Buyer pays monthly for the vehicle (higher monthlies than PCP as there is no balloon) - you are the registered keeper (but not the owner)</li>
                    <li>At the end of term, buyer owns the vehicle (optional purchase fees etc. may apply)</li>
                </ul>

                <img src={HPImage} alt="HP Example" style={{width: "100%", maxWidth: "350px"}} />

            </p>

            <Typography variant="h2" style={{fontSize: "20pt"}}>Asset Finance</Typography>
            <p>
                What is Asset Finance and how does it work?
                <ul>
                <li>Buyer can put an initial deposit on a vehicle (usually 10% minimum)</li>
                    <li>Buyer pays monthly for the vehicle - you are the registered keeper (but not the owner)</li>
                    <li>Balloon value calculated for estimated value at the end of the contract term</li>
                    <li>Buyer settles vehicle in one of 3 ways; pays off balloon, trades in or sells (subject to contract terms)</li>
                </ul>
                On the face of it, it looks similar to PCP however there are subtle differences:
                <ul>
                    <li>Typically there is no GFV - the vehicle could be worth less than what they value meaning you have to make up the difference</li>
                    <li>APR is usually a lot lower than PCP</li>
                    <li>The bank holds the final balloon, but you are still responsible for it (hence why some call it HP)</li>
                </ul>
            </p>
        
          <Typography variant="h2" style={{fontSize: "20pt"}}>PCP, HP and Asset Finance Early Exits from Contract Terms</Typography>
            <p>
                <b>TOP TIP</b> - Explore all options in your contract!
                One of the main things dealers and lenders don't tell you is that you have more options than what they offer.
                
                Settle your contract early in one of the following ways:
                <ul>
                    <li>Voluntary Termination</li>
                    <li>Trade-in</li>
                    <li>Settle your outstanding balance</li>
                    <li>Sell your car privately (check with your lender)</li>
                </ul>
                Breaking your contract early is perfectly plausable before the end of the term if you get a better outcome/deal.
                <br /><b>TOP TIP</b> - Check your contract terms (i.e. how much it costs to settle etc.), it could be another payment to take into account.
            </p>
            <Typography variant="h2" style={{fontSize: "15pt"}}>Voluntary Termination</Typography>
            <p>
                Voluntary Termination is a route that providers offer so you can get out of a contract more easily.
                This means, you must hand back the vehicle and paid over 50% of the term.

                <p />This is more an option for cars purchased via HP (Hire Purchase) because PCP (Personal Contract Plan) gives you a GFV (Gauranteed Future Value).
                <br />A good option for if the vehicle's value has crashed in value compared to what a buyer was expecting.
                <br /><b>TOP TIP</b> - Check with your lender, its good to know what options are open to you before you take out the contract!

            </p>
            <Typography variant="h2" style={{fontSize: "15pt"}}>Trade-in</Typography>
            <p>
                Trying to trade in is a good route, if you get what you want next easily. But this can be tricky as before the end of your contract, you could be in negative equity meaning you need to pay off the balance you owe. i.e. outstanding balance - trade in value
            </p>
            <Typography variant="h2" style={{fontSize: "15pt"}}>Settle outstanding balance</Typography>
            <p>
                Settling the outstanding balance could be a good route if you want to keep the motor.
                You could settle with cash (i.e. you own the full value of the vehicle), or you could settle with another finance provider (i.e. re-financing).
            </p>
            <Typography variant="h2" style={{fontSize: "15pt"}}>Privately Sell</Typography>
            <p>
                Finance providers don't actively advertise this as a viable option, however you could theoretically sell a car privately. As long as you don't receive the funds (with outstanding finance on the vehicle).
                If someone wants to buy your car, you'll need to do the following:
                <ol>
                    <li>Get permission from your lender to do this</li>
                    <li>Get a settlement quote and tell them that the car would be paid off</li>
                    <li>Get the perspective buyer to pay the funds straight to the lender</li>
                    <li>Make sure the funds are received by the finance provider and you must get a settlement letter</li>
                    <li>The transactions can now take place i.e. fill in the V5C (log book) to transfer ownership</li>
                </ol>
                <b>TOP TIP</b> - It is well known to get more for your car privately, than you can on car buying services or trade in. So why not try it?
                
                
            </p>


            <Typography variant="h2" style={{fontSize: "20pt"}}>TailoredMotors Toolbox</Typography>
            <ul>
                <li>https://www.autotrader.co.uk</li>
                <li>https://www.carwow.co.uk/pcp-calculator</li>
                <li>https://motorway.co.uk/</li>
            </ul>

        </Typography>
      </Container>
  );
}
