import React from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
    contain: {
        height: "fit-content",
        width: "100%",
        minHeight: "400px",
        paddingTop: "30px",
        paddingBottom: "30px",
        disableGutters: true,
        [theme.breakpoints.up('md')]: {
            marginTop: "200px"
        },
        [theme.breakpoints.down('sm')]: { 
            marginTop: "100px"
        },
    }
}));

export default function Blog003() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const classes = useStyles();

  return (
      <Container maxWidth="md" style={{ textAlign: "center"}} className={classes.contain}>
        <Typography variant="h2" style={{fontSize: "25pt"}}>Blog #3 - Is finance really a good idea?</Typography>
        <Typography variant="body2" style={{color: "grey", textAlign: "left"}}>
              11th January 2021 18:15
          </Typography>
          <Typography variant="body1" style={{ textAlign: "left" }}>
            <p>
                I hate to say this, but the answer is it depends. Factors such as your financial situation and/or what you're buying can impact how you're buying a motor.
                <p />

                You may opt to take finance for a reason:
                <ul>
                    <li>You want to keep your cash</li>
                    <li>You want a car above what you have in savings</li>
                    <li>You may not want to buy a depreciating assets (using GFV to your favour)</li>
                </ul>

                You may opt to pay for a motor in cash:
                <ul>
                    <li>You don't want to commit for monthly payments. For example, mortgage companies will normally reduce the amount they'd lend you as you already have a committed monthly spend.</li>
                    <li>You aren't bothered by depreciation and/or money being tied up in an asset</li>
                </ul>
            </p>
        </Typography>

        <Typography variant="caption" style={{color: "grey", textAlign: "left", fontSize: "10pt"}}>"Trust me.. never buy a depreciating asset. If it drives, flies, floats or f*cks - lease it!" - Spencer Strasmore (Dwayne Johnson) from TV Show "Ballers"</Typography>

        <Typography variant="body1" style={{ textAlign: "left" }}>
            <p>
                Some of these are a bit too sensible, and buying a car sometimes causes us petrol heads to think with our hearts rather than our heads.
                <br />
                However, how ever you decide to purchase a motor make sure you have weighed up the options and have a backout plan should you ever need to get rid of a motor (i.e. your personal circumstances changing).
            </p>
        </Typography>
      </Container>
  );
}
