import * as React from "react";
import { FeaturedPhoto } from "../components/Feature";


export function Home() {
    return (
        <>
        <div>
            <FeaturedPhoto />
        </div>
        </>
    );
  }