import {
    AppBar,
    Container,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    Fab,
    Button,
    useTheme,
} from "@material-ui/core";
import { Home, KeyboardArrowUp } from "@material-ui/icons";
import * as React from "react";
import HideOnScroll from "./HideOnScroll";
import {SideDrawer} from "./SideDrawer";
import { useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import logo from "../images/TAILORED.png";

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        [theme.breakpoints.up('md')]: {
            marginTop: "60px",
            marginLeft: "20px"
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: "20px",
            marginLeft: "10px"
          },
    },
    homeButton: {
        display: `fixed`,
        background: `url(${logo})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        
        [theme.breakpoints.up('md')]: {
            width:"300px",
            height:"90px",
          },
          [theme.breakpoints.down('sm')]: {
            width:"200px",
            height:"60px",
          },
    },
    nav: {
        minHeight: "inherit",
    },
    navBar: {
        backgroundColor: "#358597",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            height: "200px",
          },
        [theme.breakpoints.down('sm')]: {
            height: "100px",
          },
        disableGutters: true
    },
    navbarDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
        minHeight: "inherit",
        [theme.breakpoints.up('md')]: {
            paddingRight: "5vh",
          },
    },
    navListDisplayFlex: {
        display: `flex`,
        justifyContent: `space-between`,
        minHeight: "inherit",
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `white`,
    },
}));

const navLinks = [
    { title: `about`, path: `/about` },
    // { title: `contact`, path: `/contact` },
];

/**
 * Logout handler needs to be passed into this level otherwise we won't be able to logout! :D
 */
export function Header() {
    const history = useHistory();
    const classes = useStyles();
    
    return (
        <>
            <AppBar position="fixed" className={classes.navBar}>
                <Toolbar component="nav" disableGutters={true} className={classes.nav}>
                    <Container disableGutters={true}  className={classes.navbarDisplayFlex}>
                        <Container className={classes.imageContainer}>
                            <Button className={classes.homeButton} onClick={() => history.push('/')}></Button>
                        </Container>
                        <Hidden smDown>
                                <List
                                    component="nav"
                                    aria-labelledby="main navigation"
                                    className={classes.navListDisplayFlex}>
                                    {navLinks.map(({ title, path }) => (
                                        <a href={path} key={title} className={classes.linkText}>
                                            <ListItem button>
                                                <ListItemText primary={title} />
                                            </ListItem>
                                        </a>
                                    ))}
                                </List>
                        </Hidden>
                        <Hidden mdUp>
                            <SideDrawer navLinks={navLinks} />
                        </Hidden>
                    </Container>
                </Toolbar>
            </AppBar>
        </>
    );
};
