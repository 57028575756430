import {
    AppBar,
    Container,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    Fab,
    Button,
    useTheme,
    Typography,
} from "@material-ui/core";

import * as React from "react";


const useStyles = makeStyles((theme) => ({
    footer: {
        position: "absolute",
        paddingRight: "0",
        paddingLeft: "0",
        marginRight: "auto",
        marginLeft: "auto",
        backgroundColor: "#6a6867",
        [theme.breakpoints.up('md')]: {
            height: "120px",
            width: "100%"
          },
        [theme.breakpoints.down('sm')]: {
            height: "200px",
            width: "100%"
          },
    },
    leftSide: {
        width: "55%",
        float: "left",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "auto",
        marginBottom: "auto"
    },
    rightSide: {
        width: "40%",
        float: "right"
    }
}));


export function Footer() {

    const classes = useStyles();
    
    return (
       
           
                
           <Container disableGutters={true} className={classes.footer}  maxWidth={false}>
                <Container className={classes.leftSide}>
                    <Typography style={{marginTop: "10px", color: "white", fontSize:"10pt"}}>
                        The content of this website does not constitute financial advice and is provided for general information purposes only. All opinions are our own.
                    </Typography>
                    <Typography style={{marginTop: "10px", color: "white"}}>
                        © TailoredMotors 2020
                    </Typography>
                </Container>

                {/* <Container className={classes.rightSide}>
                    Links
                </Container> */}

           </Container>
             
    );
};
