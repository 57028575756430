import React from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
    contain: {
        height: "fit-content",
        width: "100%",
        minHeight: "400px",
        paddingTop: "30px",
        paddingBottom: "30px",
        disableGutters: true
    }
}));

    
export function About() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const classes = useStyles();
  return (
      <Container maxWidth="md" style={{ textAlign: "center"}} className={classes.contain}>
        <Typography variant="h2" style={{fontSize: "25pt", paddingTop: "10px"}}>About</Typography>
        <Typography variant="body1" style={{paddingTop: "10px", paddingBottom: "10px"}}>
            <p>
                Here at Tailored Motors we want to provide our opinion on what we think are great dealers with all different types of motors.
                Within this blog we will discuss financing, what to look out for when buying new and used motors, why looking at depreciation is important and many more topics...
            </p>
            <p>
                Buying Cars is a big deal, and we want to turn the tables and make more buyers informed what options there are out there.
            </p>
            <p>
                We will also get into different types of vehicles and try cover anything that moves (quite looking forward to when we finally get round to reviewing private jets).
            </p>
        </Typography>
        
      </Container>
  );
}
