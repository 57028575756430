import {
    AppBar,
    Container,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    Fab,
    Button,
    useTheme,
    Typography,
    Grid,
    Paper,
    Card,
    CardActions,
    CardContent,
    CardMedia,
} from "@material-ui/core";

import Image from "../images/cut-cars.jpg";
import FinanceImage from "../images/scott-graham-OQMZwNd3ThU-unsplash.jpg"
import FerrariImage from "../images/ferrari.jpg"
import * as React from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
    footer: {
        paddingRight: "0",
        paddingLeft: "0",
        marginRight: "auto",
        marginLeft: "auto",
        paddingBottom: "100px",
        backgroundColor: "white",
        height: "fit-content",
        width: "80%"
    },
    root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      images:{
        margin: 'auto auto auto auto',
        position: 'relative',
      },
      wrapper: {
        width: "inherit",
        height: "200px",
        objectFit: "fill",
        overflow: "hidden",
        left: "0",
      }
}));


export function HomeBlog() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const history = useHistory();

    const classes = useStyles();
    
    const redirect = (blogEntry: string) => {
        history.push(blogEntry)
    }

    return (

           <Container disableGutters={true} className={classes.footer}  maxWidth={false}>
                <Typography variant="h2"
                                color="textSecondary"
                                component="p"
                                style={{ textAlign: "center", paddingTop: "15px", fontSize: "25pt"}}>
                    All Things Cars!
                </Typography>
                <Typography variant="body1"
                            color="textSecondary"
                            component="p"
                            display="inline" 
                            style={{ textAlign: "center", paddingTop: "15px"}}>
                    Welcome to the Tailored Motors blog, where we discuss all things car related!
                    If there is a topic you want us to cover or feature, then let us know.
                </Typography>

                <Grid container spacing={2} style={{paddingTop: '30px'}}>

                <Grid item
                    sm={12}
                    md={6}>
                        <Card style={{paddingTop: '10px'}}>
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="h3"
                                align="center"
                            >
                                Is finance a good idea?
                            </Typography>
                            <Container disableGutters className={classes.wrapper}>
                                <CardMedia
                                    component="img"
                                    image={FerrariImage}
                                    className={classes.images}
                                />
                            </Container>
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    Let's take the look of the for's and against's for finance.
                                </Typography>
                            </CardContent>
                            <CardActions style={{ alignSelf: 'center' }}>
                                <Button size="large" color="primary" style={{ margin: 'auto', color: 'white', backgroundColor: '#f4a986' }} onClick={() => { redirect("/blog/is-finance-a-good-idea") }}>
                                    Read More
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                <Grid item
                    sm={12}
                    md={6}>
                        <Card style={{paddingTop: '10px'}}>
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="h3"
                                align="center"
                            >
                                Finance
                            </Typography>
                            <Container disableGutters className={classes.wrapper}>
                                <CardMedia
                                    component="img"
                                    image={FinanceImage}
                                    className={classes.images}
                                />
                            </Container>
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    Let's dive into the world of finance, how it works and differences
                                </Typography>
                            </CardContent>
                            <CardActions style={{ alignSelf: 'center' }}>
                                <Button size="large" color="primary" style={{ margin: 'auto', color: 'white', backgroundColor: '#f4a986' }} onClick={() => { redirect("/blog/finance") }}>
                                    Read More
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item
                    sm={12}
                    md={6}>
                        <Card style={{paddingTop: '10px'}}>
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="h3"
                                align="center"
                            >
                                Welcome to our blog!
                            </Typography>
                            <Container disableGutters className={classes.wrapper}>
                                <CardMedia
                                    component="img"
                                    image={Image}
                                    className={classes.images}
                                />
                            </Container>
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    Our first blog post, quick hello and what we will be talking about...
                                </Typography>
                            </CardContent>
                            <CardActions style={{ alignSelf: 'center' }}>
                                <Button size="large" color="primary" style={{ margin: 'auto', color: 'white', backgroundColor: '#f4a986' }} onClick={() => { redirect("/blog/welcome") }}>
                                    Read More
                                </Button>
                            </CardActions>
                        </Card>

                    </Grid>

                    
                </Grid>
                
           </Container>

             
    );
};
