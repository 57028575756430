import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {} from './App';
import { About } from './components/About';
import Blog001 from './components/blogs/001';
import Blog002 from './components/blogs/002';
import Blog003 from './components/blogs/003';
import { HomeBlog } from './components/HomeBlogs';
import { Home } from './screens/Home';

interface BlogRoutes {
    path: string,
    page: JSX.Element
}
const blogsRoutes: BlogRoutes[] = [
    {
        path: "/blog/welcome",
        page: <Blog001 />
    },
    {
        path: "/blog/finance",
        page: <Blog002 />
    },
    {
        path: "/blog/is-finance-a-good-idea",
        page: <Blog003 />
    }
]

export const AppRoutes = () => {

    return (
        <Switch>
            <Route
                exact
                path="/"
                render={() => {
                    return <> <Home /> <HomeBlog/> </>;
                }}
            />
            <Route
                exact
                path="/about"
                render={() => {
                    return <> <Home /> <About /> </>;
                }}
            />
            {
                blogsRoutes.map((element) => {
                    return <Route
                        exact
                        path={element.path}
                        render={() => {
                            return element.page;
                        }}
                    />
                })
            }
            
            <Route path="/" render={() => <Redirect to="/" />} />
        </Switch>
    );
};
