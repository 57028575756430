import {
    AppBar,
    Container,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Toolbar,
    Fab,
    Button,
    useTheme,
} from "@material-ui/core";
import { Home, KeyboardArrowUp } from "@material-ui/icons";
import * as React from "react";
import image from "../images/cutland.jpg"

const useStyles = makeStyles((theme) => ({
    feature_image: {
        [theme.breakpoints.up('md')]: {
            maxHeight:"500px",
            height: "480px",
            marginTop: "200px"
          },
          [theme.breakpoints.down('sm')]: {
            maxHeight:"250px",
            height: "200px",
            marginTop: "100px"
          },
          maxWidth: "100vw",
          width: "100vw",
        marginLeft: "-50vw",
        marginRight: "-50vw",
        overflow: "hidden",
        position: "relative",
        left: "50%",
        right: "50%",
    },
    imageContainer: {
        maxWidth: "100vw",
        width: "100vw",
        height: "auto",
        objectFit: "cover",
        overflow: "hidden",
        position: "absolute",
        left: "0",

        [theme.breakpoints.up('lg')]: {
            bottom: "-500px"
          },
          [theme.breakpoints.down('sm')]: {
            // bottom: "0"
          },
        
    },
}));

/**
 * Logout handler needs to be passed into this level otherwise we won't be able to logout! :D
 */
export function FeaturedPhoto() {

    const classes = useStyles();
    
    return (
        <>
            <div className={classes.feature_image}>
                <img src={image} className={classes.imageContainer}/>
            </div>
        </>
    );
};
