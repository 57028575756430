import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header';
import * as React from "react";
import { Home } from './screens/Home';
import { Footer } from './components/Footer';
import { Container } from '@material-ui/core';
import { HomeBlog } from './components/HomeBlogs';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import ScrollToTop from './components/ScrollToTop';
import ReactGA from 'react-ga';

export function App() {
  ReactGA.initialize('UA-186186754-1');

  return (
    <Router>
      <>
        <ScrollToTop />
        <Header />
        <AppRoutes />
        <Footer />
      </>
    </Router>
  );
}
